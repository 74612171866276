/* eslint-disable vue/no-side-effects-in-computed-properties */
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import moment from 'moment';

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout},
  data() {
    return {
      title: "",
      currentpage : 1,
      selectedPackage : "",
      selectedStatus : "",
      optionsStatus : [
          {
              value : "",
              text : "All"
          },
          {
              value : 1,
              text : "Active"
          },
          {
              value : 0,
              text : "Suspend"
          }
      ],
      email : ""
    }
  },
  computed: {
    users () {
      return this.$store.getters["users/users"]
    },
    loading(){
        return this.$store.getters['users/loading'];
    },
    packages () {
      var packages = this.$store.getters["packages/packages"]
      var list = packages.list.map((v)=> {return {value:v._id,text:v.name_package}})
      return [{value:"",text:"All"},...list]
    },
  },
  created(){
  },
  mounted(){
    this.$store.dispatch('users/listUser')
    this.$store.dispatch('packages/listing')
  },
  methods : {
    parseDate(date){
        return moment(date).format('llll')
    },
    pagClick(){
        var offset = this.currentpage == 0 ? 0 : (this.currentpage * 10) -10
        this.$store.commit("users/SET_OFFSET",offset);
        this.$store.dispatch("users/listUser");
    },
    changePackages(){
        this.$store.commit("users/SET_PACKAGE_ID",this.selectedPackage);
        this.$store.dispatch("users/listUser");
    },
    changeStatus(){
        this.$store.commit("users/SET_STATUS",this.selectedStatus);
        this.$store.dispatch("users/listUser");
    },
    search(){
        if(this.email.length > 2){
            this.$store.commit("users/SET_SEARCH",this.email);
            this.$store.dispatch("users/listUser");
        }else{
            this.$store.commit("users/SET_SEARCH","");
            this.$store.dispatch("users/listUser");
        }
    }
  }

};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Users </h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">Tanggal : 05-Apr-2021 | Pukul : 03:35:34 , Selamat Malam</li>
            </ol>
          </div>
        </div>
        <div class="row" >
            <div class="col-md-12">
            <b-overlay :show="loading" rounded="sm">
                <div class="card"  with overlay :aria-hidden="loading ? 'true' : null">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <fieldset>
                                <form class="d-flex justify-content-center align-items-center flex-row">
                                    <div class="form-group mx-2">
                                        <label for="">Packages : </label>
                                        <b-form-select v-model="selectedPackage" @change="changePackages()" :options="packages"></b-form-select>
                                    </div>
                                    <div class="form-group mx-2">
                                        <label for="">Status : </label>
                                        <b-form-select v-model="selectedStatus" @change="changeStatus()" :options="optionsStatus"></b-form-select>
                                    </div>
                                    <div class="form-group mx-2">
                                        <label for="">Search : </label>    
                                        <b-form-input v-model="email" placeholder="search email user" @keyup="search()"></b-form-input>
                                    </div>
                                    <div class="mx-3 mt-2">
                                        <!-- <button type="button" @click="searchnoFlag()" class="btn btn-secondary btn-sm mx-3"><i class="bx bx-filter"></i> Filter</button>
                                        <button type="button" @click="reset()" class="btn btn-warning btn-sm"><i class="bx bx-reset"></i> Reset</button> -->
                                    </div>
                                </form>
                            </fieldset>
                            <router-link tag="a" to="/users/create" class="btn btn-success btn-sm" v-b-tooltip.hover title="Jalur orang dalam">Create User</router-link>
                        </div>
                        <br>
                        <div class="table-responsive">
                            <table class="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th >FullName</th>
                                        <th >Email</th>
                                        <th >Bucket</th>
                                        <th> Register At</th>
                                        <th>Package</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="list in users.list" :key="list.id">
                                        <td>{{list.firstname}} {{list.lastname}}</td>
                                        <td>{{list.email}}</td>
                                        <td>{{list.directory}}</td>
                                        <td>{{parseDate(list.date_join)}}</td>
                                        <td>{{list.package}}</td>
                                        <td><span :class="`badge badge-pill ${list.isActive ? 'badge-soft-success' : 'badge-soft-warning'} font-size-11`">{{list.isActive ? "Active" : "Suspend"}}</span></td>
                                        <td>
                                        <router-link  tag="a" :to="`/users/detail/${list.id}`" class="btn btn-info mx-1 btn-sm">Detail</router-link>
                                        <router-link  tag="a" :to="`/users/edit/${list.id}`" class="btn btn-warning btn-sm"><i class="bx bx-pencil"></i> Edit</router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-between"> 
                                <p>Total : {{users.total}} </p>
                                <div class="overflow-auto">
                                    <div>
                                        <b-pagination
                                            :total-rows="users.total"
                                            :per-page="10"
                                            first-number
                                            v-model="currentpage"
                                            @input="pagClick()"
                                        ></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
            </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
